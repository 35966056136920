import React from 'react'

export default () => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.286 5.286a.568.568 0 0 1-.17.401l-4 4a.568.568 0 0 1-.402.17.575.575 0 0 1-.571-.571v-8c0-.313.259-.572.571-.572.152 0 .295.063.402.17l4 4c.107.107.17.25.17.402z"
      fill="#F51E50"
      fillRule="evenodd"
    />
  </svg>
)
