import React from 'react'
import Wrapper from '../styles/Wrapper'
import Header from '../components/Header'
import BackLink from '../components/BackLink'
import { media } from '../styles/minWidth'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, excerpt, html } = markdownRemark

  return (
    <Layout title={frontmatter.title} content={excerpt}>
      <Header
        background={frontmatter.image.childImageSharp.fluid.src}
        styles={{
          minHeight: 300,
          [media.medium]: { minHeight: 350 },
          [media.navigation]: { minHeight: 720 },
        }}
      >
        <Wrapper
          css={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <h1
            css={{
              color: 'white',
              marginBottom: 0,
              padding: '1em 0',
              textAlign: 'center',
              [media.navigation]: {
                fontSize: '4.375em',
              },
            }}
          >
            {frontmatter.title}
          </h1>
        </Wrapper>
      </Header>

      <Wrapper contentWidth={840}>
        <div
          css={{
            'p:first-of-type': {
              fontSize: '1.125em',
              fontStyle: 'italic',
            },
          }}
          dangerouslySetInnerHTML={{ __html: html }}
        />

        <div css={{ marginBottom: '2em' }}>
          <h3>Ingredi&euml;nten</h3>
          <div
            css={{
              [media.medium]: {
                display: 'flex',
              },
            }}
          >
            <ul
              css={{
                marginBottom: 0,
                [media.medium]: {
                  marginRight: 20,
                },
              }}
            >
              {frontmatter.ingredients
                .slice(0, Math.ceil(frontmatter.ingredients.length / 2))
                .map(ingredient => (
                  <li key={ingredient}>{ingredient}</li>
                ))}
            </ul>
            <ul>
              {frontmatter.ingredients
                .slice(Math.ceil(frontmatter.ingredients.length / 2))
                .map(ingredient => (
                  <li key={ingredient}>{ingredient}</li>
                ))}
            </ul>
          </div>
        </div>

        <div>
          <h3>Bereiding</h3>
          <ol>
            {frontmatter.preparation.map(step => (
              <li key={step}>{step}</li>
            ))}
          </ol>
        </div>

        {frontmatter.tips && (
          <div>
            <h3>Tips</h3>
            <ul>
              {frontmatter.tips.map(tip => (
                <li key={tip}>{tip}</li>
              ))}
            </ul>
          </div>
        )}

        {frontmatter.nutrition && (
          <div>
            <h3>Voedingswaarden (p.p.)</h3>
            <ul
              css={{
                listStyle: 'none',
                padding: 0,
              }}
            >
              {frontmatter.nutrition.map(nutrient => (
                <li key={nutrient.type}>
                  <strong>{nutrient.type}</strong> {nutrient.value}
                </li>
              ))}
            </ul>
          </div>
        )}

        <BackLink to="/recepten">Terug naar alle recepten</BackLink>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query RecipeByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      excerpt
      html
      frontmatter {
        path
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ingredients
        preparation
        tips
        nutrition {
          type
          value
        }
      }
    }
  }
`
