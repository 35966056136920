import React from 'react'
import Link from 'gatsby-link'
import Arrow from '../images/Arrow.js'
import { colors } from '../styles/variables'

export default ({ to, children }) => (
  <Link
    css={{
      fontFamily: 'Quicksand, sans-serif',
      fontWeight: 800,
      textDecoration: 'none',
      '> svg': {
        marginRight: 10,
        transform: 'rotate(180deg)',
      },
      ':hover > svg path': { fill: colors.hover },
    }}
    to={to}
  >
    <Arrow />
    {children}
  </Link>
)
